.uib-datepicker-popup .uib-clear {
  display: none; }

.skin-black-light .main-header > .logo,
.skin-black-light .main-header > .logo:hover,
.skin-black-light .main-header .navbar {
  background-color: #fff;
  border-bottom: 1px solid #d2d6de; }

.skin-black-light .main-header .navbar-text {
  padding-right: 15px;
  padding-left: 15px; }


md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336; }

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms; }

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0; }

[ui-view].ng-enter.ng-enter-active {
  opacity: 1; }

.Page.ng-leave.ng-leave-active {
  display: none; }

a {
  cursor: pointer; }

.alert {
  overflow: auto; }

.overlay {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); }

.box {
  min-height: 50px; }

.u-center {
  text-align: center; }

.number {
  font-family: Monospace; }

.table .number {
  text-align: right; }

.table .grow {
  width: 100%; }

.table .fit {
  width: 1%;
  white-space: nowrap; }

code {
  background: #ddd;
  color: #333; }

.pagination,
.item-count {
  margin: 10px 0; }

.item-count {
  line-height: 1.8em; }

.route-color-reference {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: grey;
  display: inline-block; }

.dropdown-menu li .text-primary {
  color: #007bff !important; }

.dropdown-menu li a.text-primary:focus,
.dropdown-menu li a.text-primary:hover {
  color: #0056b3 !important; }

.dropdown-menu li .text-secondary {
  color: #6c757d !important; }

.dropdown-menu li a.text-secondary:focus,
.dropdown-menu li a.text-secondary:hover {
  color: #494f54 !important; }

.dropdown-menu li .text-success {
  color: #3c763d !important; }

.dropdown-menu li a.text-success:focus,
.dropdown-menu li a.text-success:hover {
  color: #19692c !important; }

.dropdown-menu li .text-info {
  color: #17a2b8 !important; }

.dropdown-menu li a.text-info:focus,
.dropdown-menu li a.text-info:hover {
  color: #0f6674 !important; }

.dropdown-menu li .text-warning {
  color: #ffc107 !important; }

.dropdown-menu li a.text-warning:focus,
.dropdown-menu li a.text-warning:hover {
  color: #ba8b00 !important; }

.dropdown-menu li .text-danger {
  color: #990000 !important; }

.dropdown-menu li a.text-danger:focus,
.dropdown-menu li a.text-danger:hover {
  color: #a71d2a !important; }

.table tr:hover td {
  background-color: #ddeeff; }

.box-footer .btn {
  margin-top: 0; }


.file-upload {
  border-radius: 20px;
  border: 4px dashed #ccc;
  padding: 40px 40px 20px 40px;
  text-align: center;
  color: #999; }
  .file-upload .btn {
    margin-bottom: 20px; }
  .file-upload.file-upload-correct {
    border-color: #3c8dbc; }
  .file-upload.file-upload-wrong {
    border-color: #3c8dbc; }

.file-upload-loading {
  text-align: center;
  color: #999;
  padding: 40px 40px 20px 40px; }




.ForgotPassword-input {
  margin-bottom: 0; }

.btn-github:hover {
  color: #fff; }

.btn-facebook:hover {
  color: #fff; }

.btn-google:hover {
  color: #fff; }

.login-box-body .btn-primary {
  margin-top: 12px; }

.main-header .logo div {
  font-family: Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
  display: block;
  font-weight: 300;
  letter-spacing: -1px; }
  .main-header .logo div::first-letter {
    color: #c72c3e !important;
    font-weight: 500; }

.main-header .navbar {
  margin-left: 0px !important; }

.skin-black-light .sidebar-menu .treeview-menu li.active {
  background-color: rgba(221, 12, 46, 0.75); }
  .skin-black-light .sidebar-menu .treeview-menu li.active a {
    color: #eee; }


.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%; }

a {
  cursor: pointer; }

.form-search {
  display: inline-block; }
  .form-search input {
    border-radius: 3px; }

.highlighted {
  background-color: #fff480; }

.file-upload {
  border-radius: 20px;
  border: 4px dashed #ccc;
  padding: 40px 40px 20px 40px;
  text-align: center;
  color: #999; }
  .file-upload .btn {
    margin-bottom: 20px; }
  .file-upload.file-upload-correct {
    border-color: #3c8dbc; }
  .file-upload.file-upload-wrong {
    border-color: #3c8dbc; }

.file-upload-loading {
  text-align: center;
  color: #999;
  padding: 40px 40px 20px 40px; }

.box-tools .input-date {
  width: 160px; }

.box-tools .input-text input {
  border-radius: 3px !important; }

.highlighted {
  background-color: #fff480; }


.checkbox-inline {
  margin-right: 10px;
  margin-left: 0 !important; }

fieldset {
  border-top: #ddd thin solid;
  margin: 2em 0 1em 0;
  padding: 2em 0 0.5em 0;
  position: relative; }
  fieldset legend {
    color: #999;
    text-transform: uppercase;
    font-size: 1em;
    position: absolute;
    top: -1.25em;
    left: 1em;
    border: 0;
    background: white;
    display: inline-block;
    width: auto;
    padding: 0em 0.5em; }

.info-box-number small {
  font-weight: normal;
  color: #666;
  text-transform: uppercase;
  font-size: 0.6em !important; }


.RegisterForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px; }

.RegisterForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0; }


.ResetPassword-input {
  margin-bottom: 0; }

#routes td {
  line-height: 22px; }

#routes td .text-danger {
  float: right; }

#routes .route-type,
#routes .route-direction,
#routes .route-name {
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  margin: 0 0.25rem;
  text-align: center; }



.btn-toolbar {
  margin-bottom: 1em; }

.input-time {
  width: 50px;
  text-align: center; }


#stops td > span {
  line-height: 26px; }

#stops td span.changed {
  color: #0b93d5; }

#stops td input.changed {
  color: #0b93d5;
  border-color: #0b93d5; }

#stops td .has-error .form-control {
  border-color: #a94442;
  background-color: #ebcdcc;
  color: #000; }

#stops td a {
  line-height: 26px; }

#stops td button {
  height: 26px; }
  #stops td button .fa {
    margin: 0; }

#stops input.form-control {
  padding: 2px 4px;
  height: 26px; }

#stops .stop-time .stop-time-static {
  padding: 0.25em;
  cursor: pointer; }
  #stops .stop-time .stop-time-static:hover {
    background-color: #FFFBCC !important; }

#stops .stop-time .stop-time-form input.form-control {
  padding: 2px 0;
  text-align: center;
  width: 50px; }

.version-message {
  background-color: #ffe497;
  margin: -0.5em -0.75em 0 -0.75em;
  padding: 0.5em 0.75em; }

.leaflet-marker-icon.waypoint {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  box-shadow: 0 0 4px #000;
  background-color: #0b93d5;
  border: 2px solid #fff;
  text-align: center; }
  .leaflet-marker-icon.waypoint .fa {
    color: white;
    line-height: 16px;
    vertical-align: 3px;
    font-size: 0.9rem; }
  .leaflet-marker-icon.waypoint.waypoint-oldversion {
    background-color: #d9453c; }
  .leaflet-marker-icon.waypoint.waypoint-selected {
    border-color: yellow; }
  .leaflet-marker-icon.waypoint.large {
    width: 20px !important;
    height: 20px !important; }

.info-box-id {
  width: 120px;
  min-height: auto;
  line-height: 2rem; }
  .info-box-id .info-box-content {
    padding: 1rem; }

#stops td > span {
  line-height: 26px; }

#stops td span.changed {
  color: #0b93d5; }

#stops td input.changed {
  color: #0b93d5;
  border-color: #0b93d5; }

#stops td .has-error .form-control {
  border-color: #a94442;
  background-color: #ebcdcc;
  color: #000; }

#stops td a {
  line-height: 26px; }

#stops td button {
  height: 26px; }
  #stops td button .fa {
    margin: 0; }

#stops input.form-control {
  padding: 2px 4px;
  height: 26px; }

#stops .stop-time .stop-time-static {
  padding: 0.25em;
  cursor: pointer; }
  #stops .stop-time .stop-time-static:hover {
    background-color: #FFFBCC !important; }

#stops .stop-time .stop-time-form input.form-control {
  padding: 2px 0;
  text-align: center;
  width: 50px; }

.version-message {
  background-color: #ffe497;
  margin: -0.5em -0.75em 0 -0.75em;
  padding: 0.5em 0.75em; }

.leaflet-marker-icon.waypoint {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  box-shadow: 0 0 4px #000;
  background-color: red;
  border: 2px solid #fff;
  text-align: center;
  padding-top: 4px; }
  .leaflet-marker-icon.waypoint .fa, .leaflet-marker-icon.waypoint p {
    color: white;
    line-height: 16px;
    vertical-align: 3px;
    font-size: 12px; }
  .leaflet-marker-icon.waypoint.waypoint-oldversion {
    background-color: #d9453c; }
  .leaflet-marker-icon.waypoint.waypoint-selected {
    border-color: yellow; }
  .leaflet-marker-icon.waypoint.large {
    width: 30px !important;
    height: 30px !important; }
  .leaflet-marker-icon.waypoint .leaflet-container {
    font-size: 12px !important; }






.ocupancy-cell .seat {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: #ddd;
  margin-right: 1px; }
  .ocupancy-cell .seat.available {
    background-color: #ddd; }
  .ocupancy-cell .seat.sold {
    background-color: #31708f; }
  .ocupancy-cell .seat.occupied {
    background-color: #3Ccc3F; }
  .ocupancy-cell .seat.unregistered {
    background-color: #8f0000; }

.ocupancy-numbers {
  display: inline-block;
  width: 34px;
  text-align: right; }

#trips td {
  line-height: 22px; }

#trips td .text-danger {
  float: right; }

#trips .trip-type,
#trips .trip-direction,
#trips .trip-bus,
#trips .trip-name {
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  margin: 0 0.25rem;
  text-align: center; }

.component-trip-list {
  min-height: 300px; }

.box-header input {
  border-radius: 3px; }

.box-header .filter-item {
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 0.5em; }

.box-header .input-group {
  margin-bottom: -19px; }

.box-header .btn-group {
  margin-bottom: 2px; }

.box-header .active-filter {
  margin-bottom: -12px;
  background-color: #999;
  border-radius: 3px;
  overflow: hidden; }
  .box-header .active-filter .active-filter-value,
  .box-header .active-filter .active-filter-close-btn {
    display: block;
    line-height: 1.5;
    padding: 6px 12px;
    color: white; }
  .box-header .active-filter .active-filter-value {
    float: left; }
  .box-header .active-filter .active-filter-close-btn {
    outline: none;
    float: right;
    border: none;
    background: transparent; }
    .box-header .active-filter .active-filter-close-btn:hover {
      background-color: #aaa; }

.dropdown-menu a {
  overflow: hidden; }


.info-box-number .ocupancy-numbers {
  display: block; }

.info-box-content {
  margin-left: 0; }

.info-box-icon {
  float: right;
  font-size: 2rem;
  line-height: 2rem;
  color: #aaa;
  background: transparent;
  height: auto;
  width: auto;
  margin: 1rem; }

.smaller-gutter > div:not(:last-child) {
  padding-right: 5px; }

.smaller-gutter > div:not(:first-child) {
  padding-left: 5px; }


.dropdown .fa {
  width: 14px; }








.form-group-weekdays {
  margin: 0;
  margin-top: -4px; }
  .form-group-weekdays label {
    width: 2rem;
    text-align: center;
    margin: 0; }


.Footer {
  text-align: center;
  background-color: #F6F9FA;
  border-top: 1px solid #E9F0F3;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #888888;
  font-weight: 300; }

.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
  color: #888888; }

.Footer-link {
  text-decoration: none;
  color: #888888 !important;
  font-weight: 400; }

.Footer-link:hover {
  text-decoration: underline; }

.Footer-logo {
  width: 49px;
  height: 46px; }

.ForgotPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }


.dataTables_filter {
  float: right; }

.dataTables_filter input {
  margin: 0px 5px 0px 5px; }

.dataTables_paginate {
  float: right; }

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.login-box .login-logo a {
  font-family: Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
  display: block;
  font-weight: 300;
  letter-spacing: -1px; }
  .login-box .login-logo a::first-letter {
    color: #c72c3e !important;
    font-weight: 500; }


.Register-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.ResetPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

/*# sourceMappingURL=app.css.map */
